<template>
  <object
    scroll="no"
    style="width: 100%; height: 100vh; overflow-x: hidden !important"
    scrolling="no"
    type="text/html"
    data="https://app.powerbi.com/view?r=eyJrIjoiZTUwMzk0M2ItMzAxYy00YzMzLWEzM2QtNTZkM2RhM2E1N2VlIiwidCI6IjgyNDdiNDNkLTZiOGItNDM5OS1iNWM4LTAzZjU3Zjk5MGE2MCIsImMiOjR9"
  ></object>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AtencionSolicitudes2021",
  data() {
    return {};
  },
  computed: {
    ...mapState(["permissions"]),
  },
  mounted() {
    if (!this.permissions.includes("Atención de solicitudes 2021")) this.$router.push("/dashboard");
  },
};
</script>

<style>
</style>
